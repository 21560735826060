<template>
  <module-container
    :title="$t(sLabel)"
    route-name="invoices.debt"
    route-name-create="invoices.debt.create"
    show-toolbar
    hide-new-button
    dense
  >
    <template #route>
      <router-view :key="$route.fullPath" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class InvoicesDebt extends Vue {
  get sLabel() {
    return this.$route.name === "invoices.debt.saved"
      ? "debt.saved.collection"
      : "debt.collection";
  }
}
</script>
